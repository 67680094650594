import { createApp } from 'vue'
import App from './App.vue'
import store from './store'

import { createI18n } from 'vue-i18n'
import { languages } from '../i18n'

const currentUrl = window.location.pathname

const messages = Object.assign(languages)
const i18n = createI18n({
  locale: currentUrl === '/en' ? 'en' : 'ru',
  legacy: true,
  messages
})

const app = createApp(App)

app.use(i18n)
app.use(store)
app.mount('#app')
