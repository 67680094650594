<template>
  <form role="search" class="catalog-search" @submit.prevent="search">
    <input class="catalog-search__input" type="text" v-model.trim="searchQuery" autocomplete="off" :placeholder="$t('searchPlaceholder')">
    <button class="catalog-search__btn" type="submit"></button>
  </form>
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
  emits: ['search'],
  data () {
    return {
      searchQuery: '',
      lastSearchQuery: ''
    }
  },
  watch: {
    '$i18n.locale' () {
      if (this.isSearchResult) {
        this.setCategoryName(this.$t('searchTitle') + ': ' + this.lastSearchQuery)
        this.searchDishes(this.lastSearchQuery)
      }
    }
  },
  methods: {
    ...mapActions(['searchDishes', 'setCategoryName']),
    search (e) {
      if (this.searchQuery.length < 1) {
        return
      }

      this.setCategoryName(this.$t('searchTitle') + ': ' + this.searchQuery)
      this.searchDishes(this.searchQuery)

      this.lastSearchQuery = this.searchQuery
      this.searchQuery = ''

      e.target.blur()
      this.$emit('search')
    }
  },
  computed: {
    ...mapState(['isSearchResult'])
  }
}
</script>
