<template>
  <div class="catalog-popup" id="catalog-popup">
    <div class="catalog-popup__flex">
      <div class="catalog-popup__img double-img" :class="dishData.isVertical">
        <img class="double-back" :src="dishData.img" alt="">
        <img class="double-front" :src="dishData.img" alt="">
      </div>
      <div class="catalog-popup__wrapper">
        <div class="catalog-popup__title">{{ dishData.title }}</div>
        <div class="catalog-popup__subtitle">
          <p>Состав: {{ dishData.composition }}</p>
          <p>{{ dishData.weight }}</p>
        </div>
        <div v-if="dishData.attr">
          <div
            v-for="(attr, attrIndex) in dishData.attr"
            class="catalog-popup__item active"
            :class="{'error': uncheckedAttrs.includes(attrIndex)}"
            :key="attr.title_group">
            <div class="catalog-popup__itemTitle">{{ attr.title_group }}</div>
            <div class="catalog-popup__itemFlex">
              <label
                v-for="item in attr.data_attr"
                :key="item.attr_title"
                class="catalog-popup__itemRadio"
                :for="item.attr_title"
              >
                <input
                  :id="item.attr_title"
                  class="radio"
                  type="radio"
                  v-model="selectedAttrs[attrIndex]"
                  @change="() => onAttrSelect(item, attrIndex)"
                  :value="item"
                  :name="attr.title_group"
                  required
                >
                <span>{{ item.attr_title }}</span>
              </label>
            </div>
            <div
              class="catalog-popup__required-text"
              v-if="haveAttributes && uncheckedAttrs.includes(attrIndex)"
            >
              {{ $t('mustChoose') }}
            </div>
          </div>
        </div>
        <div class="catalog-item__bottom">
          <div class="catalog-item__price">
            <span v-if="!havePrice">По запросу</span>
            <span v-else-if="havePrice && !haveAttributes">{{ dishData.price }} ₽</span>
            <span v-else>
              {{ (!selectedAttrs || selectedPrice <= 0) ? dishData.price : selectedPrice }} ₽
            </span>
          </div>
          <div v-if="haveAttributes && !allAttributesChecked" class="catalog-item__right">
            <button class="catalog-item__basket popup-basketBtn" @click="highlightUnchecked">{{  $t('cartBtn') }}</button>
          </div>
          <AddCartBtn
            v-else
            :disabled="haveAttributes && !allAttributesChecked"
            :dishId="dishId"
            :dishSubTitle="subTitle"
            :dishTitle="dishData.title"
            :dishImg="dishData.img"
            :dishPrice="dishData.price"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AddCartBtn from '@/components/AddCartBtn'
import { mapState } from 'vuex'

export default {
  name: 'catalogPopup',
  components: {
    AddCartBtn
  },
  watch: {
    dishData: {
      handler (value) {
        this.uncheckedAttrs = []
        this.haveAttributes = value.attr && value.attr.length > 0

        if (this.haveAttributes) {
          this.selectedAttrs = new Array(value.attr.length)
          this.allAttributesChecked = false
        }

        this.selectedPrice = 0
      },
      deep: true
    }
  },
  data () {
    return {
      selectedAttrs: null,
      selectedPrice: 0,
      uncheckedAttrs: [],
      haveAttributes: false,
      allAttributesChecked: false
    }
  },
  methods: {
    highlightUnchecked () {
      for (let i = 0; i < this.dishData.attr.length; i++) {
        if (!this.selectedAttrs[i]) {
          if (this.uncheckedAttrs.indexOf(i) === -1) {
            this.uncheckedAttrs.push(i)
          }
        }
      }
    },
    onAttrSelect (attr, index) {
      if (attr.attr_price && Number(attr.attr_price) > 0) {
        this.selectedPrice = attr.attr_price
      }

      if (this.uncheckedAttrs.length > 0) {
        const idx = this.uncheckedAttrs.indexOf(index)

        if (idx > -1) {
          this.uncheckedAttrs.splice(idx, 1)
        }
      }

      for (let i = 0; i < this.dishData.attr.length; i++) {
        if (!this.selectedAttrs[i]) {
          this.allAttributesChecked = false

          return
        }
      }

      this.allAttributesChecked = true
    }
  },
  computed: {
    ...mapState(['dishData']),
    havePrice: function () {
      return this.dishData.price && Number(this.dishData.price) > 0
    },
    subTitle: function () {
      if (!this.haveAttributes) {
        return null
      }

      return this.selectedAttrs.map((attr) => attr.attr_title).join(' · ')
    },
    dishId: function () {
      if (!this.haveAttributes) {
        return this.dishData.dish_id
      }

      return this.dishData.dish_id + this.selectedAttrs.map((attr) => attr.attr_title).join('_')
    }
  }
}
</script>
