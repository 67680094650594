<template>
  <HeaderLayout/>
  <TheBasket/>
  <TheCatalog/>
  <TheGallery/>
  <TheDelivery/>
  <TheParking/>
  <CatalogPopup/>
  <ToTopBtn/>
  <TheFooter/>
</template>

<script>
import HeaderLayout from './layout/HeaderLayout.vue'
import TheBasket from './components/TheBasket.vue'
import TheDelivery from './layout/TheDelivery'
import TheParking from './layout/TheParking'
import TheGallery from './layout/TheGallery'
import TheCatalog from '@/components/TheCatalog'
import CatalogPopup from '@/components/CatalogPopup'
import ToTopBtn from '@/components/ToTopBtn'
import TheFooter from '@/layout/TheFooter'

import 'swiper/css'
import { mapActions } from 'vuex'

export default {
  name: 'App',
  components: {
    HeaderLayout,
    TheBasket,
    TheDelivery,
    TheParking,
    TheCatalog,
    CatalogPopup,
    TheGallery,
    ToTopBtn,
    TheFooter
  },
  watch: {
    '$i18n.locale' (lang) {
      if (this.$i18n.locale === 'en') {
        window.history.pushState('object or string', 'Title', '/en')
      } else {
        window.history.pushState('object or string', 'Title', '/')
      }

      this.setLanguage(lang)
    }
  },
  created () {
    this.setLanguage(this.$i18n.locale)
  },
  methods: {
    ...mapActions(['setLanguage'])
  }
}
</script>
