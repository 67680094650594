<template>
  <div class="catalog-item__right" :class="{'active': currentQuantity > 0, 'disabled': disabled }">
    <button
      class="catalog-item__minus"
      @click="() => changeQuantity('-')"
      v-if="currentQuantity > 0"
    >
    </button>
    <button
      class="catalog-item__basket popup-basketBtn"
       @click.prevent="addToCart"
    >
      {{ currentQuantity > 0 ? currentQuantity : $t('cartBtn') }}
    </button>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'AddCartBtn',
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    dishId: Number,
    dishTitle: String,
    dishSubTitle: String,
    dishImg: String,
    dishPrice: Number,
    required: Boolean
  },
  watch: {
    dishId () {
      this.updateCurrentQuantity()
    },
    cart: {
      handler (newState) {
        this.updateCurrentQuantity()
      },
      immediate: true,
      deep: true
    }
  },
  data () {
    return {
      currentQuantity: 0
    }
  },
  methods: {
    addToCart () {
      let isFound = false

      if (this.$store.state.cart && this.$store.state.cart.length > 0) {
        isFound = this.changeQuantity('+')
      }

      if (!isFound) {
        const data = {
          dishId: this.dishId,
          dishTitle: this.dishTitle,
          dishSubTitle: this.dishSubTitle ?? null,
          dishImg: this.dishImg,
          dishPrice: this.dishPrice,
          dishQuantity: 1
        }

        this.$store.dispatch('ADD_CART', data)
      }
    },
    changeQuantity (action) {
      for (let i = 0; i < this.$store.state.cart.length; i++) {
        const item = this.$store.state.cart[i]
        const id = this.dishId

        if (item.dishId === id) {
          if (action === '+') {
            this.$store.dispatch('QUANTITY_CART_ITEM', { index: i, quantity: item.dishQuantity + 1 })

            return true
          } else {
            this.$store.dispatch('QUANTITY_CART_ITEM', { index: i, quantity: item.dishQuantity - 1 })

            return true
          }
        }
      }
      return false
    },
    updateCurrentQuantity () {
      const item = this.$store.getters.getCartItemById(this.dishId)

      if (item) {
        this.currentQuantity = item.dishQuantity
      } else {
        this.currentQuantity = 0
      }
    }
  },
  computed: mapState(['cart'])
}
</script>
