<template>
  <section class="delivery" id="delivery">
    <div class="container">
      <div class="delivery-wrapper">
        <picture>
          <source srcset="https://phbar.ru/wp-content/webp-express/webp-images/uploads/2022/07/delivery-1.jpg.webp"
                  type="image/webp">
          <img class="delivery-img lazyload webpexpress-processed"
               src="https://phbar.ru/wp-content/uploads/2022/07/delivery-1.jpg" alt="" loading="lazy">
        </picture>
        <div class="delivery-circle"></div>
        <div class="delivery-circleSmall"></div>
        <div class="delivery-title delivery-index">{{ $t('deliveryTitle') }}</div>
        <div class="delivery-subtitle delivery-index">
          {{ $t('deliveryInfo') }}
        </div>
        <!-- <div class="delivery-links delivery-index">
           <div class="delivery-box">
             <a class="delivery-links__phone" href="tel:+78432923001">+7 (843) 292-30-01</a>
           </div>
           </div> -->
        <!-- <a class="delivery-phone delivery-index" href="tel:+79656182433">+7 (965) 618-24-33</a> -->
        <a class="delivery-phone delivery-index" href="tel:+79870003001">+7 (987) 000-30-01</a>
      </div>
    </div>
  </section>
</template>

<script>
</script>
