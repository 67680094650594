<template>
  <div class="mobileCatalog" v-if="this.$store.state.mobileMenu">
    <div class="mobileCatalog-top">
      <div class="mobileCatalog-top__title">{{ $t('menuSection') }}</div>
      <div class="mobileCatalog-top__close" @click.prevent="closeMobileMenu">
        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g opacity="0.3">
            <path d="M21 1L1 21M1 1L21 21" stroke="#0B1315" stroke-width="2" stroke-linecap="round"></path>
          </g>
        </svg>
      </div>
    </div>
    <TheSearch @search="onSearch"/>
    <nav class="mobileCatalog-nav catalog_menu">
      <ul>
        <li
          v-for="item in categories"
          :key="item.cat_ID"
          class="menu-item"
          :class="{
            'parent': item.sub,
            'active': activeMenuItems.includes(item.cat_ID)
          }"
          @click.stop="() => setActiveMenuItem(item)"
        >
          <a>{{ item.name }}</a>

          <ul v-if="activeMenuItems.includes(item.cat_ID) && item.sub">
            <li v-for="subItem in item.sub" :key="subItem.cat_ID">
              <a @click="() => onMenuItemClick(subItem.cat_ID, subItem.name, true)">{{subItem.name}}</a>
            </li>
          </ul>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
import Axios from 'axios'
import { mapActions, mapState } from 'vuex'
import TheSearch from '@/components/TheSearch'

export default {
  components: {
    TheSearch
  },
  emits: ['search'],
  data () {
    return {
      categories: null,
      currentMenuItemId: null,
      currentSubMenuItemId: null,
      selectedCategoryId: null,
      activeMenuItems: []
    }
  },
  watch: {
    '$i18n.locale' () {
      this.loadCategories()
    },
    topMenuCategoryId: {
      immediate: true,
      handler (id) {
        this.changeCategory(id)
      }
    }
  },
  methods: {
    ...mapActions(['setCategoryName', 'fetchDishes', 'setCurrentCategoryId']),
    setActiveMenuItem (item) {
      if (!item.sub) {
        this.onMenuItemClick(item.cat_ID, item.name, false)

        return
      }

      const itemId = item.cat_ID
      const index = this.activeMenuItems.indexOf(itemId)

      if (index !== -1) {
        this.activeMenuItems.splice(index, 1)

        return
      }

      this.activeMenuItems.push(itemId)
    },
    closeMobileMenu () {
      this.$store.dispatch('SET_MOBILE_MENU', !this.$store.state.mobileMenu)
    },
    onSearch () {
      this.closeMobileMenu()

      setTimeout(() => {
        this.$emit('search')
      }, 100)
    },
    onMenuItemClick (id, name, isSubItem = false) {
      this.closeMobileMenu()

      this.$emit('menuClick', 140)

      if (isSubItem) {
        this.currentSubMenuItemId = id
      } else {
        this.currentMenuItemId = id
      }

      this.setCategoryName(name)
      this.fetchDishes({
        id: id,
        lang: this.$i18n.locale
      })
      this.setCurrentCategoryId(id)
    },
    changeCategory (id) {
      this.selectedCategoryId = id

      this.loadCategories()
    },
    loadCategories () {
      let urlMenu = 'https://phbar.ru/wp-json/phbar/v1/mobilemenu/'

      if (this.$i18n.locale === 'en') {
        urlMenu = 'https://phbar.ru/en/wp-json/phbar/v1/mobilemenu/'
      }

      Axios.get(urlMenu)
        .then((response) => {
          this.categories = response.data
        })
    }
  },
  computed: {
    ...mapState(['topMenuCategoryId'])
  }
}
</script>

<style scoped>

</style>
