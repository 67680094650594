<template>
  <div class="catalog-left" v-if="categories">
    <div class="catalog-nav catalog_menu">
      <ul>
        <li
          v-for="item in categories"
          :key="item.cat_ID"
          :class="{
            'active parent': currentMenuItemId === item.cat_ID && item.sub
          }"
        >
          <a
            @click="() => onMenuItemClick(item.cat_ID, item.name, false)"
            :class="{'active': currentMenuItemId === item.cat_ID}"
          >
            {{ item.name }}
          </a>

          <ul v-if="currentMenuItemId === item.cat_ID && item.sub">
            <li v-for="subItem in item.sub" :key="subItem.cat_ID">
              <a
                :class="{'active': currentSubMenuItemId === subItem.cat_ID}"
                @click="() => onMenuItemClick(subItem.cat_ID, item.name, true)"
                class="'sub-sub-category'"
              >
                {{subItem.name}}
              </a>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import Axios from 'axios'
import { mapActions, mapState } from 'vuex'

export default {
  name: 'LeftMenu',
  emits: ['menuClick'],
  data () {
    return {
      categories: null,
      currentMenuItemId: null,
      currentSubMenuItemId: null,
      selectedCategoryId: null
    }
  },
  watch: {
    topMenuCategoryId: {
      immediate: true,
      handler (id) {
        this.changeCategory(id)
      }
    },
    '$i18n.locale': function () {
      this.loadCategories()
    }
  },
  methods: {
    ...mapActions(['setCategoryName', 'fetchDishes', 'setCurrentCategoryId']),
    onMenuItemClick (id, name, isSubItem = false) {
      if (isSubItem) {
        this.currentSubMenuItemId = id
      } else {
        this.currentMenuItemId = id
      }

      this.$emit('menuClick')

      this.setCategoryName(name)
      this.fetchDishes(id)
      this.setCurrentCategoryId(id)
    },
    changeCategory (id) {
      this.selectedCategoryId = id

      this.loadCategories()
    },
    loadCategories () {
      if (this.selectedCategoryId === null) {
        this.categories = null

        return
      }

      let urlMenu = 'https://phbar.ru/wp-json/phbar/v1/submenu/'

      if (this.$i18n.locale === 'en') {
        urlMenu = 'https://phbar.ru/en/wp-json/phbar/v1/submenu/'
      }

      Axios.get(urlMenu + this.selectedCategoryId)
        .then((response) => {
          if (response.data.code === 'no_sub_categories') {
            this.categories = null

            return
          }

          this.categories = response.data
        })
        .catch(() => {
          this.categories = null
        })
    }
  },
  computed: {
    ...mapState(['topMenuCategoryId'])
  }
}
</script>
