<template>
  <footer class="footer" id="contact">
    <div class="map" id="map"></div>
    <div class="container">
      <div class="footer-list">
        <a class="footer-logo" href="/">
          <picture>
            <source srcset="https://phbar.ru/wp-content/webp-express/webp-images/uploads/2022/07/logo.png.webp"
                    type="image/webp">
            <img class="img lazyload webpexpress-processed" src="https://phbar.ru/wp-content/uploads/2022/07/logo.png"
                 alt="">
          </picture>
        </a>
        <div class="footer-info">
          <div class="footer-info__address">{{ $t('siteAddressFooter') }}</div>
          <div class="footer-info__level">{{ $t('siteFloor') }}</div>
        </div>
        <div class="footer-time">
          <div class="footer-time__item">
            <p>11:00 — 00:00</p>
            <span>{{ $t('siteDayMon') }}</span>
          </div>
          <div class="footer-time__item">
            <p>11:00 — 02:00</p>
            <span>{{ $t('siteDayFr') }}</span>
          </div>
          <div class="footer-time__item">
            <p>11:00 — 00:00</p>
            <span>{{ $t('siteDayVs') }}</span>
          </div>
        </div>
        <a class="footer-email" href="mailto:ph.restoran@yandex.ru">ph.restoran@yandex.ru</a>
        <div class="footer-phone">
          <a class="footer-phone__item" href="tel:+78432923001">+7 (843) 292-30-01</a>
          <br>
          <a class="footer-phone__item" href="tel:+79656182433">+7 (965) 618-24-33</a>
          <br>
          <a class="footer-phone__email" href="mailto:ph.restoran@yandex.ru">ph.restoran@yandex.ru</a>
        </div>
      </div>
    </div>
    <div class="footer-center">
      <a class="footer-developer" href="https://kmdigital.ru" target="_blank">
        <span>Разработано в </span>
        <img class="img lazyload" src="https://phbar.ru/wp-content/themes/phbar/img/developer.svg" alt="">
      </a>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'TheFooter',
  mounted () {
    // eslint-disable-next-line no-undef
    ymaps.ready(init)

    function init () {
      // eslint-disable-next-line no-undef
      const myMap = new ymaps.Map(
        'map', {
          center: [55.790661, 49.107384],
          zoom: 16,
          controls: []
        }, {
          suppressMapOpenBlock: true
        }
      )

      // eslint-disable-next-line no-undef
      const myPlacemark = new ymaps.Placemark(
        myMap.getCenter(), {
          hintContent: 'г. Казань, Чернышевского, 27а',
          balloonContent: 'г. Казань, Чернышевского, 27а'
        }, {
          iconLayout: 'default#image',
          iconImageHref: 'https://phbar.ru/wp-content/themes/phbar/img/marker.svg',
          iconImageSize: [65, 65],
          iconImageOffset: [-32.5, -32.5]
        }
      )
      myMap.behaviors.disable('scrollZoom')
      myMap.geoObjects.add(myPlacemark)
    }
  }
}
</script>

<style scoped>

</style>
