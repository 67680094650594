<template>
  <section class="catalog" id="catalog">
    <BaseMenu
      @menuClick="() => scrollToCatalog(400)"
    />
    <MobileMenu
      @menuClick="() => scrollToCatalog(120)"
      @search="() => scrollToCatalog(120)"
    />
    <div class="catalog-bottom" id="catalog-menu">
      <div class="container">
        <div class="row" style="align-items: flex-start" ref="catalog">
          <h1>{{ selectedCategoryName }}</h1>
          <TheSearch @search="() => scrollToCatalog(120)"/>
        </div>
        <div>
          <div class="catalog-list" id="catalog-list">
            <div class="notFound" v-if="!isLoading && isSearchResult && !isFound">
              <img class="img notFound-img" src="https://phbar.ru/wp-content/themes/phbar/img/not-found.svg" alt="">
              <div class="notFound-title">{{ $t('searchNotTitle') }}</div>
              <div class="notFound-text">{{ $t('searchNotDesc') }}</div>
              <a class="notFound-btn" @click="resetCatalog">{{ $t('searchBtn') }}</a>
            </div>
            <template v-else>
              <LeftMenu @menuClick="() => scrollToCatalog(120)" />
              <div v-if="isLoading" class="catalog-items">
                <div class="catalog-block" v-for="index in 6" :key="index">
                  <div class="catalog-item catalog-item--skeleton">
                    <div class="catalog-double__img  double-img"/>
                    <div class="catalog-item__title"/>
                    <div class="catalog-item__weight"/>
                    <div class="catalog-item__bottom">
                      <div class="catalog-item__price"/>
                      <div class="catalog-item__right"/>
                      <a class="catalog-item__button"/>
                    </div>
                  </div>
                </div>
              </div>
              <div v-else class="catalog-items">
                <div class="catalog-block" v-for="item in dishes" :key="item.dish_id">
                  <CatalogItem :item="item" @cartClick="() => openPopup(item)" />
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>
      <div class="container" style="padding-bottom: 10px" v-if="dishes && (dishes.length < countDishes) && !isSearchResult">
        <div class="catalog-mobileBottom active" :class="{'loading-state': isLoadingMoreDishes}">
          <span class="catalog-mobileBottom__link" style="cursor: pointer" @click.prevent="loadMoreDishes">
            <span class="catalog-mobileBottom__link-text">{{ $t('moreBtn') }}</span>
          </span>
          <div class="catalog-mobileBottom__text">
            {{ $t('moreShow') }}: <span class="currentOffset">{{ dishes.length }}</span> {{ $t('moreShowIz') }} <span class="total_dish">{{ countDishes }}</span>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import BaseMenu from '@/components/BaseMenu'
import CatalogItem from '@/components/CatalogItem'
import MobileMenu from '@/components/MobileMenu'
import TheSearch from '@/components/TheSearch'
import LeftMenu from '@/components/LeftMenu'
import { Fancybox } from '@fancyapps/ui/dist/fancybox/fancybox.esm'

import { mapActions, mapState } from 'vuex'

export default {
  components: {
    BaseMenu,
    CatalogItem,
    TheSearch,
    LeftMenu,
    MobileMenu
  },
  watch: {
    '$i18n.locale' () {
      if (!this.isSearchResult) {
        this.updateCurrentDishes()
        this.updateCurrentCategoryName()
      }
    }
  },
  methods: {
    ...mapActions([
      'loadMoreDishes',
      'fetchDefaultCategory',
      'updateCurrentDishes',
      'updateCurrentCategoryName'
    ]),
    scrollToCatalog (offset) {
      window.scrollTo({
        top: this.$refs.catalog.getBoundingClientRect().top + window.scrollY - offset,
        behavior: 'smooth'
      })
    },
    resetCatalog () {
      this.fetchDefaultCategory()
      this.scrollToCatalog(120)
    },
    openPopup (itemData) {
      this.$store.dispatch('SET_DISHINFO', itemData)

      Fancybox.show(
        [{
          src: '#catalog-popup',
          type: 'inline'
        }],
        {
          infinite: true,
          dragToClose: false,
          trapFocus: false,
          placeFocusBack: false,
          hideScrollbar: true
        }
      )
    }
  },
  computed: {
    ...mapState([
      'isFound',
      'isLoading',
      'selectedCategoryName',
      'dishes',
      'countDishes',
      'isSearchResult',
      'isLoadingMoreDishes'
    ])
  }
}
</script>
